import React from 'react';
import { navigate } from "gatsby"
import { useStaticQuery,graphql} from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { isEmpty } from 'lodash';
import { Navbar,Container, Nav, NavDropdown} from 'react-bootstrap';
import { logout } from "../utils/auth"

const HeaderP = () => {
    const data = useStaticQuery(
        graphql`query HeaderPQuery {
            wpMenu(locations: {eq: FOOTER}) {
                menuItems {
                nodes {
                    id
                    label
                    linkRelationship
                    path
                    target
                    title
                    url
                    parentId
                    childItems {
                        nodes {
                            id
                            title
                            label
                            url
                            target
                            path
                            linkRelationship
                            parentId
                        }
                    }
                }
                }
            }
            fpage: wpPage(isFrontPage: {eq: true}) {
                homepage {
                    logo {
                        id
                        altText
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(quality: 50, placeholder: NONE, layout: FULL_WIDTH)
                            }
                            
                        }
                    }
                }
            }
        }
        `)
    const headerMenuItems = data.wpMenu.menuItems.nodes;
    const featuredImage = {
        fluid: data.fpage.homepage?.logo?.localFile?.childImageSharp?.gatsbyImageData,
        public: data.fpage.homepage?.logo?.localFile.publicURL,
        alt:data.fpage.homepage?.logo.altText || ``
    }
    const onClick =() =>{ logout(navigate(`/login/`))}
    return (
    <header>
        <Navbar bg="transparent" expand="lg" variant="white" >
            <Container>
                <Navbar.Brand href="/">
                    {featuredImage?.fluid ? (
                        <GatsbyImage image={featuredImage.fluid} alt={featuredImage.alt} />
                    ):(
                        <img src={featuredImage.public} alt={featuredImage.alt}/>
                    )}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"  />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className="justify-content-end">
                    {headerMenuItems.map((menu, index) => {
                            const hasChildren = null !== menu.childItems.nodes ? menu.childItems.nodes.length: false;
                            return(
                            <React.Fragment key={menu.id}>
                                {menu.parentId == null && !hasChildren &&(
                                    <Nav.Link id={menu.id} href={ ( menu.url ) }>{ menu.label }</Nav.Link>
                                )}
                                {hasChildren ? (
                                    <NavDropdown title={ menu.label } id={menu.id} key={'sub_'+menu.id} to={ ( menu.url )} renderMenuOnMount={true} >
                                        { menu.childItems.nodes.map(( subMenu ) => (
                                                <NavDropdown.Item key={subMenu.id} href={ ( subMenu.url ) }>{ subMenu.label }</NavDropdown.Item>
                                        ))}
                                    </NavDropdown>
                                ):null} 
                            </React.Fragment>
                            );
                        })}

                        <Nav.Link id="logout" onClick={onClick} href="#">Log Out</Nav.Link>

                    { ! isEmpty( headerMenuItems ) ? (
                        <></>
                    ) : null }
                    </Nav>
                </Navbar.Collapse>

            </Container>
        </Navbar>
    </header>
    )
    
};

/**
 *  Exporting Just the footer as well without static query for storybook,
 *  as static query does not work in storybook
 */
export default HeaderP;