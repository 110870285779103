
const isBrowser = () => typeof window !== "undefined"
const getUser = () => isBrowser() && window.localStorage.gatsbyUser ? JSON.parse(window.localStorage.gatsbyUser) : {}    
const setUser = user => isBrowser() && (window.localStorage.gatsbyUser = JSON.stringify(user))

export const handleLogin = ({ password }) => {
    if (!isBrowser()) return false;

    if (password === `kaanata123`) {
        return setUser({
            time: new Date(),
            email: `info@kaanata.com`,
        });
    }
    else {
        console.log(`Credentials do NOT match!`);
    }
    return false;
};

export const isLoggedIn = () => {
    if (!isBrowser()) return false;

    let nowDate = new Date();
    let loginDate = new Date(getUser().time);
    let diffTime = (nowDate - loginDate);
    let sessionTime = Math.round(((diffTime % 86400000) % 3600000) / 60000); // minutes
    if(sessionTime > 10) {logout(); return false;} else {
        const user = getUser();
        return !!user.email;
    }
};

export const getCurrentUser = () => isBrowser() && getUser();

//export const getCurrentUser = () =>  getUser();

export const logout = callback => {
    if (!isBrowser()) return;
    console.log(`Ensuring the \`gatsbyUser\` property exists.`);
    setUser({});
}
